import { Controller } from '@hotwired/stimulus';
import select2 from 'select2';
select2();

export default class extends Controller {

  connect() {
    $(this.element).select2();
    $(this.element).on('select2:select', function(){
      let event = new Event('change');
      this.dispatchEvent(event);
    });
  }

}
